export default {
  blogerName: 'DJWILD',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/DJWILDonline',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCvttPDvJk0IXKDiTQesLhhQ',
    },
    {
      name: 'vk',
      url: 'https://vk.com/extrajuicy',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/ca61653f7',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs01.com/cc93bf706',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/cf798a18d',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/cbbdb60e5',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/ce4b9b206',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/c875c4979',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/c95f97496',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cce2852d1',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cbe7fedac',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-irrs01.com/c30cfae69',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/cace59fcc',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/cdff1aea0',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>DJWILD</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'DJWILD',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
